import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"
import * as css from "./school-staff.module.css"

function SchoolStaff() {
  return (
    <Layout>
      <SEO title="Pracownicy szkoły - ZSP Nidek" />
      <h1>Pracownicy Szkoły</h1>
      <table className={css.table}>
        <tr>
          <td>Dyrektor szkoły</td>
          <td>mgr Ewa Snażyk-Płonka</td>
        </tr>

        <tr>
          <th colspan="2">NAUCZANIE POCZĄTKOWE</th>
        </tr>
        <tr>
          <td>Nauczanie zintegrowane</td>
          <td>
            mgr Agata Rusinek
            <br />
            mgr Ewelina Skibińska
            <br />
            mgr Anna Dyrcz
          </td>
        </tr>
        <tr>
          <th colspan="2">SZKOŁA PODSTAWOWA KL. IV-VIII</th>
        </tr>
        <tr>
          <td>Język polski</td>
          <td>
            mgr Katarzyna Rokowska
            <br />
            mgr Barbara Kubicka
          </td>
        </tr>
        <tr>
          <td>Matematyka</td>
          <td>mgr Anna Bogacz-Gałysa</td>
        </tr>
        <tr>
          <td>Historia</td>
          <td>mgr Andrzej Saferna</td>
        </tr>
        <tr>
          <td>Język angielski</td>
          <td>mgr Anna Wnęk</td>
        </tr>
        <tr>
          <td>Język niemiecki</td>
          <td>mgr Magdalena Marczyńska-Szczotka</td>
        </tr>
        <tr>
          <td>Wiedza o społeczeństwie</td>
          <td>mgr Andrzej Saferna</td>
        </tr>
        <tr>
          <td>Przyroda</td>
          <td>mgr Hanna Bryndza</td>
        </tr>
        <tr>
          <td>Fizyka</td>
          <td>mgr Alicja Kies</td>
        </tr>
        <tr>
          <td>Geografia</td>
          <td>mgr Barbara Kubicka</td>
        </tr>
        <tr>
          <td>Biologia</td>
          <td>mgr Hanna Bryndza</td>
        </tr>
        <tr>
          <td>Chemia</td>
          <td>mgr Sylwia Studnicka-Niemczyk</td>
        </tr>
        <tr>
          <td>Religia</td>
          <td>
            mgr Anna Karcz
            <br />
            mgr Jadwiga Lachendro
          </td>
        </tr>
        <tr>
          <td>Plastyka</td>
          <td>
            <span>mgr Ewa Snażyk-Płonka</span>
          </td>
        </tr>
        <tr>
          <td>Technika</td>
          <td>
            mgr Ewa Snażyk-Płonka
            <br />
            mgr Anna Bogacz-Gałysa
          </td>
        </tr>
        <tr>
          <td>Muzyka</td>
          <td>mgr Przemysław Walczak</td>
        </tr>
        <tr>
          <td>Informatyka</td>
          <td>mgr inż. Tomasz Jordanek</td>
        </tr>
        <tr>
          <td>Wychowanie fizyczne</td>
          <td>
            mgr Hanna Bryndza
            <br />
            mgr Andrzej Saferna
          </td>
        </tr>
        <tr>
          <td>Edukacja dla bezpieczeństwa</td>
          <td>mgr Andrzej Saferna</td>
        </tr>

        <tr>
          <th colspan="2">PEDAGOGIKA SPECJALNA</th>
        </tr>

        <tr>
          <td>Psycholog</td>
          <td>mgr Adriana Herma</td>
        </tr>
        <tr>
          <td>Pedagog specjalny</td>
          <td>
            mgr Marzena Noworyta
            <br />
            mgr Agnieszka Wojewodzic
          </td>
        </tr>
        <tr>
          <td>Pedagog szkolny</td>
          <td>mgr Tadeusz Mrowiec</td>
        </tr>

        <tr>
          <td>Oligofrenopedagog</td>
          <td>
            mgr Anna Dyrcz
            <br />
            mgr Marzena Noworyta
            <br />
            mgr Ewa Snażyk-Płonka
            <br />
            mgr Agnieszka Wojewodzic
          </td>
        </tr>
        <tr>
          <td>Logopeda</td>
          <td>
            mgr Marzena Noworyta <br />
            mgr Ewa Snażyk-Płonka
          </td>
        </tr>
        <tr>
          <td>Terapeuta SI</td>
          <td>mgr Ewa Snażyk-Płonka</td>
        </tr>
        <tr>
          <td>Terapeuta pedagogiczny</td>
          <td>
            mgr Anna Dyrcz
            <br />
            mgr Agata Rusinek
          </td>
        </tr>
        <tr>
          <td>Tyflopedagog</td>
          <td>mgr Lucyna Kolasa</td>
        </tr>
        <tr>
          <td>Biblioteka</td>
          <td>mgr Agnieszka Wojewodzic</td>
        </tr>
        <tr>
          <th colspan="2">PRACOWNICY ADMINISTRACJI I OBSŁUGI</th>
        </tr>
        <tr>
          <td>Główna księgowa</td>
          <td>mgr Edyta Babiuch</td>
        </tr>
        <tr>
          <td>Referent administracyjny</td>
          <td>
            mgr Edyta Babiuch
            <br />
            lic. Barbara Tobiczyk
          </td>
        </tr>
        <tr>
          <td>Intendent</td>
          <td>lic. Barbara Tobiczyk</td>
        </tr>
        <tr>
          <td>Pracownicy obsługi</td>
          <td>
            mgr Aurelia Bogunia
            <br />
            Bożena Bogunia
            <br />
            Monika Bogunia
            <br />
            Elżbieta Gąsienica-Staszeczek
            <br />
            Mariusz Bogunia
            <br />
            Bożena Mikołajek
            <br />
            Krystyna Studnicka
          </td>
        </tr>
      </table>
    </Layout>
  )
}

export default SchoolStaff
